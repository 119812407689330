@use 'bootstrap/scss/mixins/breakpoints';
@use 'variables';

.p-datatable .p-column-resizer {
  width: 1px !important;
}

.p-datatable-resizable > .p-datatable-wrapper {
  overflow-x: inherit !important;
}

.p-datatable-resizable .p-datatable-thead > tr > th {
  background-color: transparent !important;
  vertical-align: middle !important;
  padding: 0.75rem 1.25rem !important;
  border-bottom: 1px solid #dcdcdc !important;
  font-family: 'Prompt', sans-serif !important;
}

.p-datatable .p-datatable-tbody > tr > td {
  color: variables.$black-neutrals;
  white-space: normal !important;
}

@media screen and (max-width: 960px) {
  .p-datatable-resizable .p-datatable-thead > tr > th {
    white-space: normal !important;
  }
}

@media screen and (max-width: 1280px) {
  .p-datatable .p-column-resizer {
    display: none !important;
  }
  .p-datatable .p-datatable-tbody > tr > td {
    white-space: normal !important;
  }
}

.table tbody td {
  font-family: 'Bai Jamjuree', 'Prompt', sans-serif;
}

.p-datatable-thead tr th {
  &:hover {
    .p-column-resizer {
      border: solid 1px #eee !important;
    }
  }
}

.table-panel {
  $radius: variables.$border-radius;

  background: none;
  display: flex;
  flex-direction: column;

  &::ng-deep {
    .p-datatable .p-column-resizer {
      display: none;
    }
  }

  & &-body {
    flex: 1 1 auto;

    p-table {
      background-color: white;
      border-top-left-radius: $radius;
      border-top-right-radius: $radius;
      height: 100%;
    }
  }

  &-footer {
    background-color: variables.$white-pure;
    border-top: 1px solid variables.$gray-anti-flash;
    border-bottom-left-radius: $radius;
    border-bottom-right-radius: $radius;
    @include breakpoints.media-breakpoint-down('md', variables.$grid-breakpoints) {
      border-top: 0;

      app-pagination {
        display: none !important;
      }
    }
  }
}
