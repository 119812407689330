@use 'variables';
@use 'mixin';

.page-item.disabled .page-link {
  background: variables.$white-pure;
  color: #dfe6e9;
  border-color: #dfe6e9;
}

.page-item.active .page-link {
  background: variables.$royal-blue;
  color: variables.$white-pure;
  border-color: variables.$royal-blue;
  z-index: 0;
}

.page-link {
  background: variables.$white-pure;
  color: #0f2e4b;
  border-color: #dfe6e9;

  &:hover {
    background: hsla(var(--std-color-1));
    color: hsla(var(--std-color-3));
    border-color: hsla(var(--std-color-1));
  }

  &:focus {
    box-shadow: none;
    background: variables.$white-pure;
  }
}

.icon-size-res {
  @include mixin.get-res-icon-default-size();

  height: var(--icon-size);
  width: var(--icon-size);
}