@use 'bootstrap/scss/mixins/breakpoints';
@use 'variables';

.modal {
  @mixin append-navbar {
    height: calc(100vh - 65px);
    top: 65px;

    @supports (height: 100dvh) {
      height: calc(100dvh - 65px);
    }
  }

  &-backdrop {
    &.append-navbar {
      @include append-navbar;
    }
  }

  &.append-navbar {
    @include append-navbar;
  }

  &.full-device-content {
    @extend .append-navbar;

    --bs-modal-width: 100dvw;
    position: absolute;

    .modal-dialog {
      height: 100%;
      margin: 0;

      .modal-content {
        border-radius: 0;
        height: 100%;
      }
    }
  }

  & &-dialog {
    --bs-modal-margin: 1rem;
  }

  & &-content {
    --bs-modal-border-radius: #{variables.$border-radius};
    --bs-modal-inner-border-radius: #{variables.$border-radius};
  }
}

.modal-body {
  padding: 3rem;
}

.modal-content {
  border: none;
  box-shadow: 0px 3px 6px #0000001a;
}

.modal-header {
  background-color: white;
}

.modal-size {
  &-auto {
    @media (min-width: map-get(variables.$grid-breakpoints, 'sm')) {
      max-width: max-content !important;
      width: auto !important;
    }
  }

  /// Name abbreviated from square max, 500, 600 (according to responsive size - sm, md, lg)
  &-smax56 {
    $height-modal-header: 400px;

    @include breakpoints.media-breakpoint-down('md', variables.$grid-breakpoints) {
      max-width: 100% !important;

      .modal-content {
        // deduct 70px for navbar
        min-height: calc((100vh - $height-modal-header - 70px) * 0.72);
        width: 100% !important;

        @supports (min-height: 100dvh) {
          min-height: calc((100dvh - $height-modal-header - 70px) * 0.72);
        }
      }
    }

    @include breakpoints.media-breakpoint-between('md', 'lg', variables.$grid-breakpoints) {
      max-width: 500px !important;

      .modal-content {
        min-height: calc(500px - $height-modal-header);
        width: 500px !important;
      }
    }

    @include breakpoints.media-breakpoint-up('lg', variables.$grid-breakpoints) {
      max-width: 600px !important;

      .modal-content {
        min-height: calc(600px - $height-modal-header);
        width: 600px !important;
      }
    }
  }
}
