@use 'bootstrap/scss/mixins/breakpoints';
@use 'variables';

/// Making standard by overriding default popover header
.popover {
  border: none;
  border-radius: variables.$border-radius;
  box-shadow: 0px 1px 18px 0px rgba(181, 201, 235, 0.21);
  font-family: inherit;

  @include breakpoints.media-breakpoint-down(md, variables.$grid-breakpoints) {
    transform: none !important;
  }

  .popover-header {
    padding: 1.25rem 2rem;
  }

  .popover-body {
    padding: 1.25rem 2rem;
    overflow: scroll;
  }

  &:not(.free-height) {
    .popover-body {
      max-height: 500px;
    }
  }
}

.header-bg-white {
  .popover-header {
    background-color: white;
  }
}

.center-popover {
  @include breakpoints.media-breakpoint-up(md, variables.$grid-breakpoints) {
    position: fixed !important;
    transform: translate(50%, -50%) !important;
    inset: 50% 50% auto auto !important;
  }
}