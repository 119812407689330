@use 'variables';

$box-shadow: 0 0 1px hsla(0, 0%, 0%, 0.075), 0 1px 2px hsla(0, 0%, 0%, 0.075), 1px 2px 4px hsla(0, 0%, 0%, 0.075), 1px 3px 8px hsla(0, 0%, 0%, 0.075), 2px 4px 16px hsla(0, 0%, 0%, 0.075);
$green: #2FB551;
$red: #FF3B30;
$thin-red : #FFEBEA;

body.swal2-toast-shown .swal2-container {
  box-sizing: border-box;
  width: 500px;
  max-width: 100%;
  background-color: transparent;
  pointer-events: none;
}

div:where(.swal2-icon) {
  position: relative;
  box-sizing: content-box;
  justify-content: center;
  width: 5em;
  height: 5em;
  margin: 2.5em auto 0.6em;
  border: 0.25em solid transparent;
  border-radius: 50%;
  border-color: none;
  font-family: inherit;
  line-height: 5em;
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
}

.swal2-popup.swal2-toast {
  box-sizing: border-box;
  grid-column: 1/4 !important;
  grid-row: 1/4 !important;
  padding: 1em;
  overflow-y: hidden;
  background: variables.$white-pure;
  box-shadow: $box-shadow;
  pointer-events: all;
}

.swal2-popup.swal2-toast .swal2-styled {
  margin: 0;
  padding: 12px;
  font-size: 1em;
}

.swal2-popup.swal2-toast.bg-border-error.swal2-show {
  padding: 7px;
}

div#swal2-html-container {
  margin: 0px;
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-cancel:focus {
  box-shadow: none;
}

div:where(.swal2-container) div:where(.swal2-popup) {
  display: none;
  position: relative;
  box-sizing: border-box;
  grid-template-columns: minmax(0, 100%);
  width: 32em;
  max-width: 100%;
  padding: 0 0 1.25em;
  border: none;
  border-radius: 8px;
  background: variables.$white-pure;
  color: #545454;
  font-family: inherit;
  font-size: 1rem;
}

.icon-btn-svg-alert {
  height: 68px;
  width: 78px;
}

.swal2-popup.swal2-toast .swal2-title {
  padding: 0;
  font-size: 1em;
  text-align: initial;
}

.bg-border-error {
  border: 1px solid $red;
  background-color: $thin-red !important;
  padding: 15px !important;


  .swal2-icon.swal2-icon-show {
    border: 1px solid $red !important;
    background-color: $red;
    box-shadow: $box-shadow;
  }

  button.swal2-close {
    color: #FF3B30;
    font-size: 1.5em !important;
  }
}

.bg-border-error-sub-text {
  border: 1px solid $red;
  background-color: $thin-red !important;
  padding: 15px !important;

  h2#swal2-title {
    margin-top: 5px;
    margin-bottom: 0;
    margin-left: 3px;
  }

  div#swal2-html-container {
    margin: 3px;
  }

  .swal2-icon.swal2-icon-show {
    border: 1px solid $red !important;
    background-color: $red;
    box-shadow: $box-shadow;
  }

  button.swal2-close {
    color: #FF3B30;
    font-size: 1.5em !important;
  }
}

.bg-border-publish {
  border: 1px solid variables.$blue-sapphire;
  background-color: #E8EEFB !important;
  padding: 15px !important;

  .swal2-icon.swal2-icon-show {
    border: 1px solid variables.$blue-sapphire !important;
    background-color: variables.$blue-sapphire;
    box-shadow: $box-shadow;
  }

  button.swal2-close {
    color: #1750C0;
    font-size: 1.5em !important;
  }
}

.bg-border-success {
  border: 1px solid $green;
  background-color: #EBF9EE !important;
  padding: 15px !important;

  .swal2-icon.swal2-icon-show {
    border: 1px solid $green !important;
    background-color: $green;
    box-shadow: $box-shadow;
  }

  button.swal2-close {
    color: #2FB551;
    font-size: 1.5em !important;
  }
}

div:where(.swal2-container) button:where(.swal2-close):focus {
  outline: none;
  box-shadow: none;
}

.swal2-popup.swal2-toast .swal2-title {
  margin: revert-layer;
  margin-left: 3px;
}