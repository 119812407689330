@use 'bootstrap/scss/mixins/breakpoints';
@use 'variables';

@each $breakpoint-name, $breakpoint-value in variables.$grid-breakpoints {
  $infix: breakpoints.breakpoint-infix($breakpoint-name, variables.$grid-breakpoints);

  @if $infix == '' {
    @each $key, $value in variables.$spacers {
      .fs-base#{$infix}-#{$key} {
        font-size: variables.$default-font-size * $value !important;
      }

      .fs#{$infix}-#{$key} {
        font-size: #{$value}rem !important;
      }
    }
  } @else {
    @include breakpoints.media-breakpoint-up($breakpoint-name, variables.$grid-breakpoints) {
      @each $key, $value in variables.$spacers {
        .fs-base#{$infix}-#{$key} {
          font-size: variables.$default-font-size * $value !important;
        }
      }
      @each $key, $value in variables.$spacers {
        .fs#{$infix}-#{$key} {
          font-size: #{$value}rem !important;
        }
      }
      @each $key, $value in variables.$font-weights {
        .fw#{$infix}-#{$key} {
          font-weight: $value !important;
        }
      }
    }
  }
}

/// @deprecated
.f-14 {
  font-size: 14px;
}

/// @deprecated
.f-16 {
  font-size: 16px;
}

/// @deprecated
.f-18 {
  font-size: 18px;
}

/// @deprecated
.f-20 {
  font-size: 20px;
}

.fs-subtitle {
  font-size: 0.7rem;
}

.text-primary {
  color: #0f2e4b !important;
}

.text-black {
  color: black !important;
}

.text-asterisk {
  color: variables.$red-light-carmine-pink !important;
}

.text-yellow {
  color: #fec107 !important;
}

.text-green {
  color: #1abc9c !important;
}

.text-grey {
  color: #cccccc !important;
}

.text-white {
  color: #ffffff !important;
}

.text-blue {
  color: #3294c1 !important;
}

/// Please use text-decoration-underline from bootstrap instead.
.text-underline {
  text-decoration: underline;
}

.text-truncate-clip {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
}