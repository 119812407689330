@use 'variables';

.ng-select.ng-select-single .ng-select-container {
  height: 32px;
}

.invalid-required.ng-select.is-invalid .ng-select-container {
  border-color: red !important;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("/assets/svg/warning.svg");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.normal-ng-select .ng-select-container {
  border-radius: 4px !important;
  border: 1px solid variables.$gray !important;
  background-color: variables.$white-pure !important;
}


.ng-select.ng-select-multiple.ng-select-disabled>.ng-select-container .ng-value-container .ng-value {
  padding: 5px;
  background-color: variables.$white-pure;
  border: 1px solid variables.$gray-anti-flash;
  border-radius: 30px !important;
  min-width: 30%;
  margin: 0px;
}

.hidden-arrow-ng-select .ng-arrow-wrapper {
  display: none;
}

.border-none .ng-select-container.ng-has-value {
  border: 0;

  .ng-value-container {
    column-gap: 3px !important;
    row-gap: 10px !important;
    padding: 10px !important;
  }
}