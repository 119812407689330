@use 'variables';

.custom-day {
  text-align: center;
  display: inline-block;
  height: 25px;
  width: 42px;
}

.custom-day.focused {
  background-color: #e6e6e6;
}

.active-datepicker-from-date.range {
  background-color: hsl(var(--std-color-3)) !important;
  color: variables.$white-pure;
  border-radius: 30px 0 0px 30px !important;

}

.active-datepicker-to-date.range {
  background-color: hsl(var(--std-color-3)) !important;
  border-radius: 0 30px 30px 0 !important;
  color: variables.$white-pure;
}

.active-datepicker-form-date-equal-to-date.range {
  background-color: hsl(var(--std-color-3)) !important;
  border-radius: 30px 30px 30px 30px !important;
}


.custom-day:hover {
  background-color: hsl(var(--std-color-3));
  color: variables.$white-pure;
}

.custom-day.faded {
  background-color: hsl(var(--std-color-3));
  color: variables.$white-pure;
  border-radius: 0px;
}

.span-calendar {
  z-index: 1;
}

.custom-datepicker {
  border-radius: variables.$border-radius-input;
  padding: 0.375rem 0.75rem;
  border: 1px solid #eaeaed;

  &:disabled {
    background-color: variables.$gray-anti-flash;
    border: 1px solid variables.$gray-anti-flash !important;
    opacity: 1;
  }
}

ngb-datepicker.dropdown-menu {
  padding: 1rem !important;
}

.ngb-dp-header {
  border-bottom: 0;
  border-radius: .25rem .25rem 0 0;
  padding-top: .25rem;
  background-color: variables.$white-pure !important;
}

.ngb-dp-weekdays {
  border-bottom: 0px !important;
  border-radius: 0;
  background-color: variables.$white-pure !important;
}

.btn:not([class*=btn-std]) {
  box-shadow: none !important;
  border-radius: 5px;
}

.ngb-dp-weekday,
.ngb-dp-week-number {
  line-height: 2rem;
  text-align: center;
  font-style: normal !important;
  color: #1958D3 !important;
  font-weight: 600 !important;
  margin: 5px !important;
  padding-left: 10px !important;

}

.ngb-dp-day,
.ngb-dp-weekday,
.ngb-dp-week-number {
  width: calc(variables.$default-font-size * 1.5) !important;
  height: calc(variables.$default-font-size * 1.5) !important;
  font-size: calc(variables.$default-font-size * 1);
  margin: 5px;
  text-align: right;
}

.ngb-dp-day.disabled {
  color: variables.$gray-anti-flash !important;
}

.ngb-dp-arrow {
  width: 0.5rem !important;
}

.ngb-dp-navigation-chevron {
  color: #55596D;
  border-top-right-radius: 2px;
  border-width: .15em .15em 0 0 !important;
}

.ngb-dp-navigation-select {
  gap: 4px;
}

ngb-datepicker-navigation-select > .form-select {
  font-size: .775rem !important;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M17 8L12 3L7 8" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M17 16L12 21L7 16" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>') !important;
}

ngb-datepicker-navigation-select > .form-select:last-child {
  max-width: 4.5rem !important;
}

[ngbDatepickerDayView] {
  text-align: center;
  width: 42px !important;
  height: 25px !important;
  line-height: 24px !important;
  border-radius: .25rem;
  background: transparent;
}

.ngb-dp-month:first-child .ngb-dp-week {
  padding-left: 0 !important;
}

.ngb-dp-month:last-child .ngb-dp-week {
  padding-right: 8px !important;
}

[ngbDatepickerDayView]:hover:not(.bg-primary),
[ngbDatepickerDayView].active:not(.bg-primary) {
  background-color: hsl(var(--std-color-1)) !important;
  color: variables.$white-pure;
  border-radius: 30px !important;
  outline: 1px solid var(--bs-border-color);
}

[ngbDatepickerDayView].active.bg-primary {
  background-color: hsl(var(--std-color-3)) !important;
  outline: 1px solid var(--bs-border-color);
  border-radius: 30px !important;
}

[ngbDatepickerDayView].btn-light.bg-primary.text-white {
  background-color: hsl(var(--std-color-3)) !important;
  border-radius: 30px;
}

.bs-datepicker-container {
  padding: 39px 37px;

  .bs-datepicker-head {
    background-color: transparent;

    button {
      font-size: 0.875rem;
      color: #212529;

      .previous,
      .next {
        font-weight: 200;
      }

      &:hover {
        background-color: hsl(var(--std-color-3));
        color: #ffffff;
        border-radius: 30px;
      }
    }
  }
}

.bs-datepicker-body table td {
  font-size: 0.875rem;
  color: #212529;
}

.bs-datepicker-body span:hover {
  background-color: hsl(var(--std-color-3)) !important;
  color: #ffffff;
  border-radius: 30px;
}

.bs-datepicker-body table td span.selected {
  background-color: hsl(var(--std-color-3)) !important;
}
