@use 'bootstrap/scss/mixins/breakpoints';
@use 'variables';
@use 'mixin';
@use 'mixins/input';

$bubble-dropdown-gutter: calc(variables.$default-font-size / 4);

%transition-dropdown-item {
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);

  &:active {
    background-color: darken(variables.$gray-anti-flash, 5%);
  }

  &:hover {
    background-color: hsl(var(--std-color-2));
    color: hsl(var(--std-color-3));
  }
}

%ng-dropdown-panel-std-row {
  @extend %ng-select-std-skin-var;

  box-shadow: none;
  border-color: var(--ng-select-border-color);
  border-radius: var(--ng-select-radius) !important;
  height: max-content;

  &.ng-select-bottom {
    .ng-dropdown-panel-items {
      border-bottom-left-radius: var(--ng-select-radius);
      border-bottom-right-radius: var(--ng-select-radius);
    }
  }

  &.ng-select-top {
    .ng-dropdown-panel-items {
      border-top-left-radius: var(--ng-select-radius);
      border-top-right-radius: var(--ng-select-radius);
    }
  }

  .ng-dropdown-panel-items {
    .ng-optgroup,
    .ng-option {
      @extend %transition-dropdown-item;
      color: var(--ng-select-color);


      &.ng-option-marked,
      &.ng-option-selected {
        background-color: var(--ng-select-active-bg);
        color: var(--ng-select-active-color);
      }

      &.ng-option-selected {
        font-weight: 600;
      }

      .icon-add-item {
        color: variables.$gray-silver-sand-mid;
      }
    }
  }
}

%ng-dropdown-panel-std-round {
  @extend %ng-dropdown-panel-std-row;

  padding: 0.5rem;

  .ng-dropdown-panel-items {
    border-radius: var(--ng-select-radius) !important;

    .ng-option {
      border-radius: var(--ng-select-radius);

      &:not(:first-child) {
        margin-top: 0.25rem;
      }
    }
  }
}

%ng-dropdown-panel-bubble-skin {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  padding: 0 !important;

  .ng-dropdown-header {
    border-bottom: none;
    padding: 0;
  }

  .ng-dropdown-panel-items {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;

    .ng-option {
      &.ng-option-marked,
      &.ng-option-selected {
        background-color: transparent;
      }

      &:not(:last-child) {
        border-radius: 0 !important;
      }

      &:last-child {
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
      }
    }
  }

  @each $direction in (top, bottom) {
    &.ng-select-#{$direction} {
      margin-#{$direction}: 0.5rem;

      .ng-dropdown-panel {
        border-#{$direction}-left-radius: 0 !important;
        border-#{$direction}-right-radius: 0 !important;
      }
    }
  }
}

.dropdown-std {
  .dropdown-menu {
    $padding-x: 1.75rem;
    $padding-y: 1.5rem;

    border-radius: variables.$border-radius;
    border: 0;
    box-shadow:
      0px 3px 5px 0px rgba(132, 147, 198, 0.12),
      0px 1px 18px 0px rgba(181, 201, 235, 0.21);
    min-width: 15rem;
    padding: calc($padding-y / 2) calc($padding-x / 2);
  }

  .dropdown-item {
    @extend %transition-dropdown-item;
    border-radius: variables.$border-radius-input;
    color: variables.$gray-pantone;
    font-size: 1rem;
    margin-top: 0.25rem;
    padding: 0.375rem 0.5rem;
    transition-property: font-weight, background-color, color;

    &:first-child {
      margin-top: 0;
    }

    &:hover {
      font-weight: 600;
    }

    &:active {
      background-color: hsl(var(--std-color-3));
      color: hsl(var(--std-color-2));
      font-weight: 600;
    }
  }
}

%ng-select-std-skin-var {
  --ng-select-arrow-color: #{variables.$gray-pantone};
  --ng-select-border-color: #{variables.$gray-anti-flash};
  --ng-select-box-shadow-color: #{change-color(variables.$gray-pantone, $alpha: 0.05)};
  --ng-select-color: #{variables.$gray-neutrals};
  --ng-select-active-color: hsl(var(--std-select-active-text));
  --ng-select-active-bg: hsl(var(--std-select-active-bg));
  --ng-select-selected-color: #{variables.$gray-pantone};
  --ng-select-min-width: 14rem;
  --ng-select-radius: #{variables.$border-radius-input};
}

%ng-select-opened-with-search-icon {
  &.ng-select-opened {
    .ng-select-container {
      .ng-value-container {
        > .selected-item {
          display: none;
        }

        > .ng-value {
          display: none;
        }

        > .ng-placeholder {
          display: none;
        }

        .ng-input {
          background-image: url("data:image/svg+xml,%3csvg id='search' width='17' height='17' viewBox='0 0 24 24' fill='none' stroke='%2355596D' stroke-width='1.5' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M17 17L21 21' stroke='inherit' stroke-width='inherit' stroke-linecap='round' stroke-linejoin='round' /%3e%3cpath d='M3 11C3 15.4183 6.58172 19 11 19C13.213 19 15.2161 18.1015 16.6644 16.6493C18.1077 15.2022 19 13.2053 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11Z' stroke='inherit' stroke-width='inherit' stroke-linecap='round' stroke-linejoin='round' /%3e%3c/svg%3e");
          background-repeat: no-repeat;
          max-height: none;
          overflow: hidden;
          opacity: 1;
          padding: 0 0 0 2rem !important;
          position: static !important;

          @include breakpoints.media-breakpoint-up('lg', variables.$grid-breakpoints) {
            background-image: url("data:image/svg+xml,%3csvg id='search' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%2355596D' stroke-width='1.5' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M17 17L21 21' stroke='inherit' stroke-width='inherit' stroke-linecap='round' stroke-linejoin='round' /%3e%3cpath d='M3 11C3 15.4183 6.58172 19 11 19C13.213 19 15.2161 18.1015 16.6644 16.6493C18.1077 15.2022 19 13.2053 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11Z' stroke='inherit' stroke-width='inherit' stroke-linecap='round' stroke-linejoin='round' /%3e%3c/svg%3e");
          }

          > input {
            max-height: none;
            opacity: 1;

            &::placeholder {
              color: #686e75 !important;
            }
          }
        }
      }
    }

    &.ng-select-top {
      .ng-select-container {
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
      }
    }

    &.ng-select-bottom {
      .ng-select-container {
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }

      .ng-dropdown-panel {
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
      }
    }
  }

  .ng-select-container {
    .ng-value-container {
      > .ng-placeholder {
        position: static;
      }

      > .ng-input {
        @include mixin.get-res-icon-default-size();

        // Workaround instead display:none because display:none doesn't focus on click.
        max-height: 0;
        line-height: var(--icon-size);
        padding: 0 !important;

        input {
          // Workaround instead display:none because display:none doesn't focus on click.
          max-height: 0;

          &::placeholder {
            color: transparent !important;
          }
        }
      }
    }
  }
}

ng-select {
  &.std-skin {
    @include input.get-h-input-res();
    @extend %ng-select-std-skin-var;

    --ng-select-height: var(--input-height);

    %ng-value-container {
      padding: {
        top: 0;
        bottom: 0;
        left: calc(variables.$default-font-size * 0.75);
        right: calc(variables.$default-font-size * 1.5);
      }
    }

    %reposition-ng-placeholder {
      > .ng-placeholder {
        top: auto;
        position: absolute;
        padding: 0;
      }
    }

    &.ng-select-single {
      .ng-select-container > .ng-value-container {
        @extend %ng-value-container;
        @extend %reposition-ng-placeholder;

        > .ng-input {
          padding: {
            top: 0;
            bottom: 0;
            left: calc(variables.$default-font-size * 0.75);
            right: calc(variables.$default-font-size * 3);
          }
          position: absolute;
          top: auto;
        }
      }
    }

    &.ng-select-multiple {
      .ng-select-container {
        > .ng-value-container {
          @extend %ng-value-container;
          @extend %reposition-ng-placeholder;
          row-gap: normal;

          > .ng-value {
            margin: 5px 0;
          }

          > .ng-input {
            padding: 0;
            position: static;
          }
        }
      }
    }

    .ng-arrow {
      display: none !important;
    }

    %ng-arrow-wrapper {
      border-color: var(--ng-select-arrow-color) !important;
      position: absolute;
      right: calc(variables.$default-font-size * 0.625);
      border-top: 1px solid;
      border-left: 1px solid;
    }

    .ng-select-container {
      border-color: var(--ng-select-border-color);
      border-radius: var(--ng-select-radius);
      box-shadow: none;
      // height: var(--ng-select-height) !important;
      min-height: var(--ng-select-height);
      min-width: var(--ng-select-min-width);
      color: var(--ng-select-color);
      padding-right: calc(variables.$default-font-size * 0.75);

      .ng-arrow-wrapper {
        @extend %ng-arrow-wrapper;
        border-top-left-radius: 2px !important;
        height: 8px;
        margin-left: 10px;
        margin-right: 10px;
        width: 8px !important;
        transform: rotate(225deg);
        top: calc((100% - 10px) / 2);
      }

      .ng-clear-wrapper {
        --icon-size: 1.5rem;

        @include breakpoints.media-breakpoint-up('lg', variables.$grid-breakpoints) {
          background-image: url("data:image/svg+xml,%3csvg id='circle-xmark' stroke='%239da0ab' width='24' height='24' viewBox='0 0 24 24' fill='white' xmlns='http://www.w3.org/2000/svg'%3e %3cpath d='M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3e %3cpath d='M9.1712 14.8284L11.9996 12M14.8281 9.17157L11.9996 12M11.9996 12L9.1712 9.17157M11.9996 12L14.8281 14.8284' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3e %3c/svg%3e");
        }

        background-image: url("data:image/svg+xml,%3csvg id='circle-xmark' stroke='%239da0ab' width='20' height='20' viewBox='0 0 24 24' fill='white' xmlns='http://www.w3.org/2000/svg'%3e %3cpath d='M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3e %3cpath d='M9.1712 14.8284L11.9996 12M14.8281 9.17157L11.9996 12M11.9996 12L9.1712 9.17157M11.9996 12L14.8281 14.8284' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3e %3c/svg%3e");
        background-repeat: no-repeat;
        background-size: contain;
        height: var(--icon-size);
        position: absolute;
        right: calc(variables.$default-font-size * 0.75);
        top: auto;
        bottom: calc((100% - var(--icon-size)) / 2);
        width: var(--icon-size);
        z-index: 2;

        .ng-clear {
          display: none;
        }
      }

      &:hover {
        box-shadow: none;
      }

      .ng-value-container {
        > .ng-placeholder {
          color: variables.$gray-neutrals;
        }
      }

      .ng-value {
        font-weight: 600;
      }
    }

    &.ng-select {
      &.ng-select-opened {
        > .ng-select-container {
          border-color: var(--ng-select-border-color);
          border-radius: var(--ng-select-radius);
        }

        .ng-arrow-wrapper {
          @extend %ng-arrow-wrapper;
          transform: rotate(45deg);
          top: calc((100% - 5px) / 2);
        }
      }

      &.ng-select-focused:not(.ng-select-opened) {
        &.show-focused {
          > .ng-select-container {
            border-color: var(--ng-select-border-color);
            box-shadow:
              inset 0 1px 1px rgba(#000, 0.075),
              0 0 1px 2px var(--ng-select-box-shadow-color);
          }
        }

        > .ng-select-container {
          border-color: var(--ng-select-border-color);
          box-shadow: none;
        }
      }
    }

    &:not(.panel-clinging) {
      .ng-dropdown-panel {
        &.ng-select-bottom {
          bottom: unset;
          top: calc(100% + 0.25rem);
        }

        &.ng-select-top {
          bottom: calc(100% + 0.25rem);
          top: unset;
        }
      }
    }

    .ng-dropdown-panel {
      @extend %ng-dropdown-panel-std-round;
    }
  }

  &.opened-search-icon {
    @extend %ng-select-opened-with-search-icon;
  }

  &.panel-std-round {
    .ng-dropdown-panel {
      @extend %ng-dropdown-panel-std-round;
    }
  }

  &.bubble-dropdown {
    .ng-select-container {
      .ng-value-container {
        padding-top: calc(variables.$default-font-size * 0.625 - $bubble-dropdown-gutter) !important;
        padding-bottom: calc(variables.$default-font-size * 0.625 - $bubble-dropdown-gutter) !important;
        padding-left: calc(variables.$default-font-size * 0.75 - $bubble-dropdown-gutter) !important;

        > .ng-placeholder {
          padding: 0 calc(variables.$default-font-size * 0.25) !important;
        }
      }

      &.ng-has-value {
        .ng-clear-wrapper {
          display: block;
        }
      }

      .ng-input {
        margin: calc($bubble-dropdown-gutter - 1px);

        input {
          opacity: 0;
        }
      }
    }

    &.search-in-dropdown {
      &.ng-select-opened {
        @each $direction in top, bottom {
          &.ng-select-#{$direction} {
            > .ng-select-container {
              border-#{$direction}-left-radius: 0;
              border-#{$direction}-right-radius: 0;
            }
          }
        }
      }

      .ng-dropdown-panel {
        @extend %ng-dropdown-panel-bubble-skin;
      }
    }

    &:not(.search-in-dropdown) {
      @extend %ng-select-opened-with-search-icon;

      .ng-dropdown-panel {
        &.ng-select-top {
          border-bottom-left-radius: 0 !important;
          border-bottom-right-radius: 0 !important;
          bottom: 100%;

          .ng-dropdown-panel-items {
            border-bottom-left-radius: 0 !important;
            border-bottom-right-radius: 0 !important;

            .ng-option {
              &:not(:first-child) {
                border-radius: 0;
              }

              &:first-child {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
              }
            }
          }
        }

        &.ng-select-bottom {
          border-top-left-radius: 0 !important;
          border-top-right-radius: 0 !important;
          top: 100%;

          .ng-dropdown-panel-items {
            border-top-left-radius: 0 !important;
            border-top-right-radius: 0 !important;

            .ng-option {
              &:not(:last-child) {
                border-radius: 0;
              }

              &:last-child {
                border-top-left-radius: 0;
                border-top-right-radius: 0;
              }
            }
          }
        }
      }
    }

    &.ng-select-opened {
      .ng-select-container {
        .ng-clear-wrapper {
          display: none !important;
        }
      }
    }

    .ng-dropdown-panel {
      .ng-dropdown-panel-items {
        .ng-option {
          &:not(:first-child) {
            margin-top: 0;
          }
        }
      }

      padding: 0 !important;
    }
  }

  &.std-v3 {
    &.ng-select {
      > .ng-select-container {
        @include mixin.reset-input;
        @include mixin.style-input;

        .ng-value-container .ng-input > input {
          height: auto;
          background-color: unset !important;
        }

        &:disabled {
          @include mixin.reset-input;
          @include mixin.style-input;
          @include mixin.disable-input-bg;
        }
      }

      &.ng-select-focused {
        .ng-select-container {
          box-shadow: none !important;
          border-color: lightgray !important;
        }
      }
    
      &.ng-select-single .ng-select-container {
        @include mixin.style-input;
      }
  
      &.ng-select-multiple .ng-select-container {
        @include mixin.style-input;
      }
  
      &.ng-select-disabled > .ng-select-container {
        @include mixin.style-input;
        @include mixin.disable-input-bg;
      }
  
      &.ng-select-disabled .ng-arrow-wrapper {
        display: none;
      }
    }
  }
}

.ng-dropdown-panel {
  &.std-skin {
    @extend %ng-dropdown-panel-std-round;
  }

  &.bubble-dropdown {
    @extend %ng-dropdown-panel-bubble-skin;
  }
}
