@use 'variables';

.chrome-picker {
  box-shadow: none !important;
  width: 215px !important;
}

.chrome-hue {
  margin: 0 !important;
}

color-editable-input span {
  margin: 0.5rem !important;
  color: variables.$white-pure !important;
  font-size: 14px !important;
  font-family: 'Proxima Nova', sans-serif !important;
}

color-editable-input input {
  text-align: right !important;
  padding: 1rem !important;
  margin-right: 1rem !important;
  color: variables.$white-pure !important;
  font-family: 'Arial', sans-serif !important;
  border-radius: 3px;
  background: #1E1E1E;
  box-shadow: none !important;
  width: 100px !important;
}

.color-hue-slider {
  border-radius: 50%;
  width: 5px !important;
  height: 5px !important;
  margin-top: 0 !important;
  background-color: transparent !important;
  outline: 3px solid variables.$white-pure !important;
  transform: translate(0px) !important;
  cursor: pointer;
}

.color-hue {
  border-radius: 12px !important;
  height: 5px !important;
  left: 0.5rem !important;
  right: 0.5rem !important;
}

.saturation {
  padding-bottom: 100% !important;
}

.chrome-toggle {
  display: none !important;
}

.chrome-controls {
  position: relative;
}

.chrome-color {
  width: unset !important;
  position: absolute;
  top: calc(100% + 16px + 0.4rem);
  right: calc(100px - 0.75rem);
}

.chrome-swatch,
.chrome-active {
  border-radius: 3px !important;
  width: 1.25rem !important;
  height: 1.25rem !important;
}

.chrome-body,
.custom-color-picker {
  padding: 0 !important;
  background-color: #000000 !important;
}

.wrap {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding-bottom: 1rem;
}
