.toggle-switch {
  position: relative;
  display: inline-block;
  width: 35px;
  height: 18px;
  top: 2px
}

.toggle-switch .toggle-input {
  display: none;
}

.toggle-switch .toggle-label {
  position: absolute;
  top: -6px;
  left: 0;
  width: 42px;
  height: 25px;
  background-color: #bfbfbf;
  border-radius: 34px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 0px;
}

.toggle-switch .toggle-label::before {
  content: "";
  position: absolute;
  width: 21px;
  height: 21px;
  border-radius: 50%;
  top: 2px;
  left: 2px;
  background-color: #fff;
  box-shadow: none !important;
  transition: transform 0.3s;
}

.toggle-switch .toggle-input:checked+.toggle-label {
  background-color: hsl(var(--std-toggle-active-bg));
  margin-top: 0px
}

.toggle-switch .toggle-input:checked+.toggle-label::before {
  transform: translateX(16px);
  left: 3px !important;
}

.toggle-switch.light .toggle-label {
  background-color: #BEBEBE;
}

.toggle-switch.light .toggle-input:checked+.toggle-label {
  background-color: #9B9B9B;
}

.toggle-switch.light .toggle-input:checked+.toggle-label::before {
  transform: translateX(6px);
}

.toggle-switch.dark .toggle-label {
  background-color: #4B4B4B;
}

.toggle-switch.dark .toggle-input:checked+.toggle-label {
  background-color: #717171;
}

.toggle-switch.dark .toggle-input:checked+.toggle-label::before {
  transform: translateX(16px);
}