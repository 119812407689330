$bai-jamjuree-font-weights: () !default;
$bai-jamjuree-font-weights: map-merge(
  (
    'BaiJamjuree-ExtraLight': 200,
    'BaiJamjuree-Light': 300,
    'BaiJamjuree-Regular': 400,
    'BaiJamjuree-Medium': 500,
    'BaiJamjuree-SemiBold': 600,
    'BaiJamjuree-Bold': 700
  ),
  $bai-jamjuree-font-weights
);

$bai-jamjuree-italic-font-weights: () !default;
$bai-jamjuree-italic-font-weights: map-merge(
  (
    'BaiJamjuree-ExtraLightItalic': 200,
    'BaiJamjuree-LightItalic': 300,
    'BaiJamjuree-Italic': 400,
    'BaiJamjuree-MediumItalic': 500,
    'BaiJamjuree-SemiBoldItalic': 600,
    'BaiJamjuree-BoldItalic': 700
  ),
  $bai-jamjuree-italic-font-weights
);

@each $name, $weight in $bai-jamjuree-font-weights {
  @font-face {
    font-family: Bai Jamjuree;
    src: url('../assets/fonts/Bai_Jamjuree/#{$name}.ttf') format('truetype');
    font-weight: $weight;
    font-style: normal;
  }
}

@each $name, $weight in $bai-jamjuree-italic-font-weights {
  @font-face {
    font-family: Bai Jamjuree;
    src: url('../assets/fonts/Bai_Jamjuree/#{$name}.ttf') format('truetype');
    font-weight: $weight;
    font-style: italic;
  }
}

@font-face {
  font-family: 'helvethaica';
  src: url('../assets/fonts/helvethaica/DB-Helvethaica-X-Med-v3.2.ttf');
  /* src: url('../assets/fonts/helvethaica/DB-Helvethaica-X-v3.2.ttf'); */
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'helvethaica';
  src: url('../assets/fonts/helvethaica/DB-Helvethaica-X-Med-It-v3.2.ttf');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'helvethaica';
  src: url('../assets/fonts/helvethaica/DB-Helvethaica-X-Med-It-v3.2.ttf');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'helvethaica';
  src: url('../assets/fonts/helvethaica/DB-Helvethaica-X-v3.2.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'THSarabunNew';
  src: url('../assets/fonts/THSarabunNew.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'THSarabunNewBold';
  src: url('../assets/fonts/THSarabunNewBold.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'THSarabunNewBoldItalic';
  src: url('../assets/fonts/THSarabunNew BoldItalic.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'THSarabunNewItalic';
  src: url('../assets/fonts/THSarabunNew Italic.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'Prompt';
  src: url('../assets/fonts/Bai_Jamjuree/BaiJamjuree-Medium.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'Prompt';
  src: url('../assets/fonts/Bai_Jamjuree/BaiJamjuree-Light.ttf');
  font-weight: 300;
}

@font-face {
  font-family: 'Prompt';
  src: url('../assets/fonts/Bai_Jamjuree/BaiJamjuree-SemiBold.ttf');
  font-weight: bold;
}

@font-face {
  font-family: 'Charmonman';
  src: url('../assets/fonts/Charmonman/Charmonman-Regular.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Charmonman';
  src: url('../assets/fonts/Charmonman/Charmonman-Bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Parisienne';
  src: url('../assets/fonts/Parisienne/Parisienne-Regular.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'Sriracha';
  src: url('../assets/fonts/Sriracha/Sriracha-Regular.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'DancingScript';
  src: url('../assets/fonts/Dancing_Script/DancingScript-Regular.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'NotoSansThai';
  src: url('../assets/fonts/NotoSansThai/NotoSansThai-Regular.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'Mitr';
  src: url('../assets/fonts/Mitr/Mitr-Regular.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'Taviraj';
  src: url('../assets/fonts/Taviraj/Taviraj-Regular.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'Charm';
  src: url('../assets/fonts/Charm/Charm-Regular.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'Charmonman';
  src: url('../assets/fonts/Charmonman/Charmonman-Regular.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'Chonburi';
  src: url('../assets/fonts/Chonburi/Chonburi-Regular.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'Mali';
  src: url('../assets/fonts/Mali/Mali-Regular.ttf');
  font-weight: normal;
}


@font-face {
  font-family: 'Pattaya';
  src: url('../assets/fonts/Pattaya/Pattaya-Regular.ttf');
  font-weight: normal;
}