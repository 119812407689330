@use 'variables';

.table {
  th,
  td {
    padding: 1.25rem;
    background: unset;
    color: unset;
  }

  &-v4 {
    $padding-x: 16px;
    $radius: variables.$border-radius;

    background: none;
    box-shadow: 4px 0px 0px #223c72;

    thead tr {
      border-bottom: 1px solid variables.$border-table-color;

      th {
        padding: 14px $padding-x;

        &:first-child {
          border-top-left-radius: $radius;
        }

        &:last-child {
          border-top-right-radius: $radius;
        }
      }
    }

    tbody tr {
      $time-transition: .25s;
      $time-function: ease-out;

      border-bottom: 1px solid #eaeaed;
      transition: background-color $time-transition $time-function;

      &:hover {
        background-color: variables.$gray-light;
      }

      td {
        padding: 8px $padding-x;
        transition: box-shadow $time-transition $time-function;
      }

      &.tr-checked {
        background-color: variables.$gray-light;

        td:first-child {
          box-shadow: 4px 0px 0px 0px hsl(var(--std-toggle-active-bg)) inset;
        }
      }
    }
  }
}

.table thead th {
  vertical-align: bottom;
  border: none;
  text-align: left;
}

.table-shadow {
  box-shadow: 0px 3px 6px #0000001a;
}

.p-datatable .p-datatable-thead > tr > th {
  color: variables.$black-neutrals;
  background: #fff !important;
}
