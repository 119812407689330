@use 'bootstrap/scss/mixins/breakpoints';
@use 'variables';

@mixin get-h-input-res {
  --input-height: #{variables.$height-input-default-sm};

  @include breakpoints.media-breakpoint-up('lg', variables.$grid-breakpoints) {
    --input-height: #{variables.$height-input-default};
  }
}

@mixin h-input-res {
  @include get-h-input-res;
  height: var(--input-height);
}