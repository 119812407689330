@use 'variables';

$font-size: map-get(variables.$font-size-lower, 1);
$input-size: 32px;
$color: variables.$gray-neutrals;
$border-color: variables.$gray-anti-flash;
$border-radius: variables.$border-radius-input;

%pagination-std-skin-var {
  --bs-pagination-font-size: #{$font-size};
  --bs-pagination-color: #{$color};
  --bspagination-bg: white;
  --bs-pagination-border-width: 1px;
  --bs-pagination-border-color: #{$border-color};
  --bs-pagination-border-radius: #{$border-radius};
  --bs-pagination-active-color: hsl(var(--std-color-4));
  --bs-pagination-active-bg: white;
  --bs-pagination-active-border-color: var(--std-bg-btn);
  --bs-pagination-hover-color: #{$color};
  --bs-pagination-hover-bg: #{$border-color};
  --bs-pagination-hover-border-color: #{$border-color};
  --bspagination-active-color: #{$color};
  --bspagination-active-bg: #{$border-color};
  --bspagination-active-border-color: #{$border-color};
  --bs-pagination-disabled-bg: white;
  --bs-pagination-disabled-border-color: #{$border-color};
}

ngb-pagination {
  &.std-skin {
    .pagination {
      @extend %pagination-std-skin-var;

      li.page-item {
        margin: 0 5px;
      }

      .page-item {
        .page-link {
          border-radius: var(--bs-pagination-border-radius);
          text-decoration: none;
          min-width: $input-size;
          height: $input-size;
          font-size: $font-size;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 7px;
        }
      }
    }
  }
}